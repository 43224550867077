import React from 'react';
import './style/App.scss';
import BlogHead from "./components/Header";
import Navigation from "./components/Navigation";
import BlogFooter from "./components/BlogFooter";
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import Content from "./components/Content";

function App({store}) {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className="App">
                    <BlogHead/>
                    <Navigation/>
                    <Content/>
                    <BlogFooter/>
                </div>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
