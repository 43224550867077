import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';
import hljs from 'highlight.js';

import './styles/hljs-github.css';

function MarkedOutput({textarea}) {
    marked.setOptions({
        highlight: function (code) {
            return hljs.highlightAuto(code).value;
        },
    });

    return (
        <div id="output" dangerouslySetInnerHTML={{__html: marked(textarea)}}/>
    );
}

MarkedOutput.propTypes = {
    textarea: PropTypes.string,
};

export default MarkedOutput;
