import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { composeWithDevTools } from 'redux-devtools-extension';

import {applyMiddleware, createStore} from 'redux';
import * as serviceWorker from './serviceWorker';
import {reducer} from './reducers/reducer';
import createSagaMiddleware from 'redux-saga'
import {allSagas} from "./sagas/sagas";


let sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(sagaMiddleware)));




sagaMiddleware.run(allSagas);
ReactDOM.render(<App store={store}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
