import React from 'react';
import { useState } from 'react';

import Editor from './Editor';
import MarkedOutput from './MarkedOutput';
import { InitialTextValue } from './utils/helpers';

import './styles/ribbon.css';
import './styles/MarkdownEditor.css';

function MarkdownEditor() {
  const [textarea, setTextarea] = useState(InitialTextValue());

  const handleChange = textarea => {
    setTextarea(textarea);
  };

  return (
    <div id="container">
      <Editor textarea={textarea} handleChange={handleChange} />
      <MarkedOutput textarea={textarea} />
    </div>
  );
}

export default MarkdownEditor;
