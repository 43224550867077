import {requestGET} from "./requestSagas";
import {actionRequest, actionSuccess, GET_ALL_BLOG_ENTRIES} from "../actions/actionList";
import {takeEvery, all} from "redux-saga/effects"

export function* getAllBlogEntries(action) {
    console.log("SAGA: " + JSON.stringify(action))
    let getRequestSaga = requestGET("https://griffitelle-backend.herokuapp.com");
    yield getRequestSaga({type: GET_ALL_BLOG_ENTRIES});
}

export function* allSagas() {
    yield all([
        takeEvery(GET_ALL_BLOG_ENTRIES, getAllBlogEntries)
    ])
};
