import React from "react"

class BlogFooter extends React.Component {
    render() {
        return (
            <div className="temporary-app-footer">
                <div className="App-footer">
                    Have a nice day!
                </div>
                <div className="blue-accent"/>
                <div className="dark-blue"/>
                <div className="green-accent"/>
                <div className="accent-1"/>
                <div className="accent-2"/>
                <div className="accent-3"/>
            </div>
        )
    }
}

export default BlogFooter;