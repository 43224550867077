import React from "react"

import ReactMarkdown from "react-markdown/with-html";
import styled from 'styled-components/macro';
import {lightBlue, seafoamGreen} from "../Constants";

const PostHeader = styled.h2`
        background-color: white;
        padding: 0.5rem;
        color: black;
        border: solid 5px;
        border-image: linear-gradient(to bottom right, ${lightBlue} 0%, ${seafoamGreen} 100%);
        border-image-slice: 1;
`
const BlogPostContent = styled.div`
    padding-bottom: 0.5rem;
`

class BlogPost extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            postContent: props.content,
            title: props.title
        }

    }
    render() {
        return (<div className="blog-post">
                <PostHeader>{this.props.title}</PostHeader>
                <BlogPostContent>
                    <ReactMarkdown
                        source={this.state.postContent}
                        escapeHtml={false}
                    />
                </BlogPostContent>
                <button className="Blog-post-comment-button">This button doesn't do
                    anything
                </button>

            </div>
        )
    }
}

export default BlogPost;