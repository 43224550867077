export const NAVIGATION = {
    HOME: "HOME",
    OTHER: "OTHER",
    ABOUT: "ABOUT"

};


export const lightBlue= '#2d72bc';

export const seafoamGreen= "#61c69d";
export const darkBlue= "#062F4F";
export const darkEarth= "#34152c";
export const darkTeal= "#303f39";
export const yellowAccent= "#FBEEC1";

export const buttonBackground= darkBlue;
