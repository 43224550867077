import {actionSuccess, GET_ALL_BLOG_ENTRIES} from "./actionList";

export function actionFromType(type, payload, payloadName) {
  if (!payload) {
    console.log("WAT", payload)
    return {type: type}
  } else if (payload && !payloadName) {
    return {type: type, payload: payload}
  } else {
    console.log("OHHH")
    return {type: type, [payloadName]: payload}
  }
}

export function getAllEntriesAction(dispatch) {
  return dispatch({type: GET_ALL_BLOG_ENTRIES, url: "https://griffitelle-backend.herokuapp.com/"});
}

export function getAllEntriesSuccess(data) {
  return {type: actionSuccess(GET_ALL_BLOG_ENTRIES), data}
}
