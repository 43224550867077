import {NAVIGATION} from "../Constants";
import {actionRequest, actionSuccess, GET_ALL_BLOG_ENTRIES} from "../actions/actionList";

export function reducer(state, action) {
  try {
    console.log("REDUCER: " + action.type)

    switch (action.type) {
      case "NAVIGATION": {
        console.log("HI! Going to : " + action.destination);
        return {
          ...state,
          navigation: action.body
        }
      }
      case actionRequest(GET_ALL_BLOG_ENTRIES): {
        return {
          ...state
        }
      }
      case actionSuccess(GET_ALL_BLOG_ENTRIES): {
        console.log("SUCCESS!!", JSON.stringify(action))
        return {
          ...state,
          blogEntries: action.response,
        }
      }
      default: {
        console.log("No action type!", action.type)
        return {
          ...state
        }
      }
    }
  } catch (e) {

    console.log("Exception!", e);
    return state;
  }
}
