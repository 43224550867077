import React from "react"
import BlogContent from "./BlogContent";
import {Route, Switch} from "react-router-dom";
import logo from "../images/rabbit.svg"
import MarkdownEditor from "./markdown-editor/MarkdownEditor";

class Content extends React.Component {
    render() {
        return (<div>
            <Switch>
                <Route exact path="/">
                    <div id="logo"><img src={logo} alt="This is rabbit carrying the flag du jour"/>
                        <div id="logo-subtext">Welcome! Grab a cookie and have a seat. Oh, there aren't any cookies? Sorry, can't do much
                            about that right now.
                        </div>
                    </div>
                </Route>
                <Route exact path="/blog" component={BlogContent}/>
                {/*<Route path="/game/isPlaying/:isPlaying" component={Game}/>*/}
                <Route path="/about">
                    <div>ABOUT</div>
                </Route>
                <Route path="/create" component={MarkdownEditor}/>
            </Switch>
        </div>)
    }
}

export default Content;
