import {put, call} from "redux-saga/effects";
import {actionError, actionRequest, actionSuccess} from "../actions/actionList";
import axios from 'axios'
import {actionFromType} from "../actions/actions";
import styled from 'styled-components/macro';

const GameHeader = styled.h2`
`

export function requestSaga(requestFunc, errorMessage) {
  let generator = function* (action) {
    try {
      yield put({type: actionRequest(action.type)});
      const {data} = yield call(requestFunc, action.payload);
      yield put(actionFromType(actionSuccess(action.type), data, "response"));
    } catch (e) {
      yield put(actionFromType(actionError(action.type), e));
    }
  }
  return generator;
};

export function requestGET(url) {
  return requestSaga(() => axios.get(url), "Error GETTING");
}
