import React from "react"

class Header extends React.Component {
    render() {
        return (
            <div className="App-header">
                <h6>The highly suspect ruminations and markedly functional productions of</h6>
                <h1>Elise Griffiths</h1>
            </div>
        )
    }
}

export default Header;