import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import codemirror from 'codemirror';
import 'codemirror/mode/gfm/gfm';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/base16-light.css';
import {PrimaryButton} from "../../style/genericStyledComponents";

function Editor({textarea, handleChange}) {
    const editorEl = useRef();

    let editor = <textarea ref={editorEl} defaultValue={textarea}/>;

    useEffect(
        () => {
            // code mirror setup
            editor = codemirror.fromTextArea(editorEl.current, {
                forceTextArea: true,
                mode: 'gfm',
                lineNumbers: true,
                matchBrackets: true,
                lineWrapping: true,
                tabSize: 2,
            });
            editor.on('change', () => handleChange(editor.getValue()));

            // empty editor
            window.addEventListener('keyup', handleEmptyEditor);

            // clean up
            return () => {
                window.removeEventListener('keyup', handleEmptyEditor);
            };
        },
        [editorEl],
    );


    const handleEmptyEditor = e => {
        if (e.ctrlKey && e.shiftKey && e.keyCode === 69) {
            e.preventDefault();
            e.stopPropagation();

            editor.setValue('');
        }
    };

    let onSend = () => {
        console.log("HIII?");
    }

    return (
        <div>
            <div>Here's some stuff!
                <PrimaryButton onClick={onSend}> Send it</PrimaryButton>
            </div>
            <div>{editor}

            </div>
        </div>
    );
}

Editor.propTypes = {
    textarea: PropTypes.string,
};

export default Editor;
