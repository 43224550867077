import styled from 'styled-components/macro';
import {buttonBackground, lightBlue, seafoamGreen} from "../Constants";

export const PrimaryButton = styled.button`
    background-color: ${buttonBackground};
    padding: 0.5rem;
    font-size: 1.0rem;
    color: white;
    border: none;
    margin: 0.5rem;
    `