import React from "react";
import BlogPost from "./BlogPost";
import {connect} from "react-redux"
import {getAllEntriesAction} from "../actions/actions"


class BlogContent extends React.Component {
    componentDidMount() {
        this.props.getAllBlogEntries();
    }

    render() {

        let blogEntries = this.props.blogEntries;
        let blogPosts;
        if (blogEntries) {
            blogPosts = blogEntries.map(e =>
                <BlogPost key={e.id} title={e.title} content={e.content}/>)
        }


        return (
            <div className="blog-container">
                <div className="spacer-left"/>
                <div className="blog-left-column"></div>
                <div className="blog-content">
                    {blogPosts}
                </div>
                <div className={"blog-right-column"}></div>
                <div className="spacer-right"/>
            </div>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
        getAllBlogEntries: () => getAllEntriesAction(dispatch)
    })
}

const mapStateToProps = (state) => {
    if (!state) {
        console.log("STATE ERROR: " + state)
        return {};
    }
    console.log("STATE ASSIGNED: ", state);
    return {blogEntries: state.blogEntries};

};


export default connect(mapStateToProps, mapDispatchToProps)(BlogContent)
