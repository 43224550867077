import React from "react"
import {connect} from 'react-redux'
import {NavLink} from "react-router-dom";

class Navigation extends React.Component {

    render() {

        return <div className="navigation-bar">
            <NavLink className="navigation-button" exact to="/">HOME</NavLink>
            <NavLink className="navigation-button" exact to="/blog">BLOG</NavLink>
            <NavLink className="navigation-button" exact to="/create">CREATE ENTRY</NavLink>
            <NavLink className="navigation-button" to="/game">GAME</NavLink>
            <NavLink className="navigation-button" to="/about">ABOUT</NavLink>
        </div>


    }

}

function mapStateToProps(state) {
    return {
        navigation: state.navigation
    };
}

export default connect(mapStateToProps)(Navigation);
